import * as React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

class ServicesHome extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="Contact" />

                <ContentSection>
                    <Row>
                        <Col
                            lg={6}
                            xl={4}
                            className="d-flex flex-column justify-content-center align-items-start"
                        >
                            <h1>Contact Info</h1>

                            <address className="my-4">
                                <h3 className="h5">Tiber Creek Headquarters</h3>

                                <div className="mb-3">
                                    12015 Lee Jackson Memorial Hwy
                                    <br />
                                    Suite 600
                                    <br />
                                    Fairfax, VA 22033
                                </div>

                                <ul className="list-unstyled">
                                    <li>Telephone: +1 703 766 2150</li>
                                    <li>
                                        General Communication:{" "}
                                        <a href="mailto:info@tibercreek.com">
                                            info@tibercreek.com
                                        </a>
                                    </li>
                                    <li>
                                        Business Development/Partnering:{" "}
                                        <a href="mailto:sales@tibercreek.com">
                                            sales@tibercreek.com
                                        </a>
                                    </li>
                                    <li>
                                        Careers:{" "}
                                        <a href="mailto:careers@tibercreek.com">
                                            careers@tibercreek.com
                                        </a>
                                    </li>
                                </ul>
                            </address>
                        </Col>
                        <Col lg={6} xl={8}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3106.791353624153!2d-77.36518268465079!3d38.860132779576475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64f69dee77a4d%3A0x30ae53940a8e12f7!2sManTech%2C+12015+Lee+Jackson+Memorial+Hwy+%23600%2C+Fairfax%2C+VA+22033!5e0!3m2!1sen!2sus!4v1522261544499"
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                            ></iframe>
                        </Col>
                    </Row>
                </ContentSection>

                <ContentSection>
                    <h2 className="mb-5">Office Locations</h2>
                    <Row>
           

                            <Card color="light">
                                <CardBody>
                                    <address className="mb-5">
                                        <h3 className="h5">K-12 IT Services</h3>

                                    <div className="mb-5">
                                            10130 Mallard Creek Rd
                                            <br />
                                            Suite 300
                                            <br />
                                            Charlotte, NC 28262
                                        </div>

                                        <ul className="list-unstyled mb-5">
                                            <li>Telephone: +1 704 996 3718</li>
                                            <li>Fax: +1 704 944 3101</li>
                                            <li>
                                                Email:{" "}
                                                <a href="mailto:info@tibercreek.com">
                                                    k-12@tibercreek.com
                                                </a>
                                            </li>
                                        </ul>
                                    </address>
                                </CardBody>
                            </Card>
                    </Row>
                </ContentSection>
            </Layout>
        );
    }
}

export default ServicesHome;
